<template>
  <div>
    <web-header active-url="/books" borderBottom></web-header>
    <div class="page-article-list">
      <div class="f-wrap">
        <div class="page-header">
          <div class="page-header-en">PUBLICATIONS</div>
          <span class="page-header-cn">出版物</span>
        </div>
        <div class="page-book-wrap">
          <a :href="'/book_detail/'+item.class_id+'/'+item.id" target="_blank" class="page-book-wrap-item" v-for="(item, index) in dataList" :key="index">
            <div class="page-book-wrap-item-box">
              <div class="icon" v-if="item.litpic && item.litpic.img">
                <i class="point-left"></i>
                <i class="point-right"></i>
                <el-image :src="item.litpic.img"></el-image>
              </div>
              <div class="title">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </a>
        </div>
        <div class="page-list-pagination" v-if="page.total > page.pageSize">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.pageSize"
              layout="prev, pager, next, jumper"
              :total="page.total">
          </el-pagination>
        </div>
        <div class="page-list-pagination-btn" v-if="page.total > page.pageSize">
          <el-button type="primary" size="medium" round @click="dataMore" v-if="!noneMore">查看更多</el-button>
          <div class="none-more" v-else>没有更多了</div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import { articleSection, articleList } from "@/api";
export default {
  name: 'Books',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data() {
    return {
      // 数据列表
      dataList: [],
      // 分页
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        // 总条数
        total: 0
      },
      noneMore: false,
    }
  },
  created() {
    this.getParams();
    this.getTableData();
  },
  methods: {
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      url = `${url}/books?page=${this.page.current}`;
      history.replaceState(null, null, url);
    },
    // 获取书籍列表数据
    async getTableData() {
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: "sort",
        sortedBy: "desc",
        search: "",
        searchFields: ""
      };
      let res = await articleList(param, 17);
      console.log(res)
      if (res && res.code == 200) {
        this.dataList = res.data.list;
      }
      this.updateUrl();
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getTableData();
    },
    // 加载更多
    dataMore() {
      let ps = this.current + 1;
      if (this.page.total > ps*page.pageSize) {
        this.current = this.current + 1;
        this.getTableData();
      } else {
        this.noneMore = true;
      }
    }
  }
}
</script>

<style lang="scss">
.page-article-list {
  .page-header-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    &-item {
      width: 25%;
      height: 60px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      line-height: 60px;
      font-size: 20px;
      cursor: pointer;
      background: #f7f7f7;
      &.active {
        background: #004898;
        color: #ffffff;
      }
    }
  }
  .page-header-desc {
    margin-top: 60px;
    &-text {
      text-align: center;
      font-size: 14px;
      color: #333333;
    }
    &-link {
      display: flex;
      justify-content: flex-end;
      a {
        color: #2C83FF;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .page-swiper {
    position: relative;
    margin: 40px 0;
    &-content {
      width: 80%;
      margin: 0 auto;
      .text-wrap {
        position: relative;
        font-size: 28px;
        line-height: 36px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .year {
          cursor: pointer;
        }
        .line {
          width: 100px;
          height: 10px;
          background: url('~@/assets/time_line.png') no-repeat;
          background-size: 100% 100% !important;
          position: absolute;
          left: 85px;
          display: inline-block;
        }
      }
      .slide-active {
        .text-wrap {
          color: #004898 !important;
        }
      }
    }
    .line-button-prev, .line-button-next {
      position: absolute;
      cursor: pointer;
      top: 0;
      color: #333;
      width: 35px;
      height: 35px;
    }
    .line-button-prev {
      left: 0;
      background: url('~@/assets/more_one.png') no-repeat;
      background-size: 100% 100%;
      &:hover {
        background: url('~@/assets/more_one_bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .line-button-next {
      right: 0;
      background: url('~@/assets/more_two.png') no-repeat;
      background-size: 100% 100%;
      &:hover {
        background: url('~@/assets/more_two_bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .page-book-wrap {
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: calc(25% - 50px);
      margin-right: 66px;
      margin-bottom: 60px;
      &:nth-child(4n) {
        margin-right: 0!important;
      }
      &-box {
        border: 2px solid #909495;
        .icon {
          position: relative;
          height: 320px;
          .point-left, .point-right {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #909495;
            position: absolute;
          }
          .point-left {
            bottom: 0;
            left: 0;
          }
          .point-right {
            bottom: 0;
            right: 0;
          }
          .el-image {
            width: 100%;
            height: 320px;
          }
        }
        .title {
          border-top: 3px solid #909495;
          height: 75px;
          text-align: center;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &:hover {
        .page-book-wrap-item-box {
          border-color: #004898;
          .icon {
            .point-left, .point-right {
              background-color: #004898;
            }
          }
          .title {
            border-top-color: #004898;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page-article-list {
    .page-swiper {
      margin: 20px 0;
      &-content {
        .text-wrap {
          font-size: 26px;
          line-height: 36px;
          .line {
            width: calc(100% - 75px);
            max-width: 129px;
            height: 12px;
            left: 75px;
          }
        }
      }
    }
    .page-header-tab {
      &-item {
        font-size: 16px;
        height: auto;
        line-height: 40px;
        &.active {
          background: #004898;
          color: #ffffff;
        }
      }
    }
    .page-book-wrap {
      &-item {
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &-box {
          .icon {
            height: auto;
            .el-image {
              height: auto;
            }
          }
          .title {
            height: 100px;
            span {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .page-article-list {
    .page-swiper {
      margin: 20px 0;
      &-content {
        width: 70%;
        .text-wrap {
          font-size: 22px;
          line-height: 36px;
          .line {
            width: calc(100% - 75px);
            max-width: 129px;
            height: 10px;
            left: 65px;
          }
        }
      }
    }
    .page-header-tab {
      &-item {
        font-size: 14px;
        height: auto;
        line-height: 40px;
        &.active {
          background: #004898;
          color: #ffffff;
        }
      }
    }
    .page-header-desc {
      margin-top: 60px;
      &-text {
        text-align: center;
        font-size: 14px;
        color: #333333;
      }
      &-link {
        display: flex;
        justify-content: flex-end;
        a {
          color: #2C83FF;
          display: block;
          margin-top: 10px;
        }
      }
    }
    .page-book-wrap {
      &-item {
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
        &-box {
          .icon {
            height: auto;
            .el-image {
              height: auto;
            }
          }
          .title {
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>